<template>
	<no-auth>
		<div style="background: #fff;padding: 15px;">
			<div class="filter-container">
				<!-- 代理等级 -->
				<div class="filter-item">
					<label class="label">关键字: </label>
					<el-input v-model="searchKey" @keyup.enter.native="goodsSearch" placeholder="商品名称" style="width: 200px;;margin-right: 10px;"
					 clearable></el-input>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:25px;width:90px;" @click="goodsSearch">查询</el-button>
				</div>
			</div>
			<div style="color: #999;font-size: 13px;margin-bottom: 20px;">已入库{{StockedCount}}件商品，还可添加{{surplusCount}}件</div>
			<div class="table-container">
				<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable">
					<el-table-column label="商品" width="450">
						<template slot-scope="scope">
							<div class="product-info" style="align-items:flex-start">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="width:300px;" class="overflowTwo">
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
										</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Price" label="价格">
						<template slot-scope="scope">
							<div v-if='scope.row.PriceType == 1'>{{scope.row.Price}}</div>
							<div v-if='scope.row.PriceType == 2'>{{scope.row.Price}} ~ {{scope.row.Price2}}</div>
							<div v-if='scope.row.PriceType == 3'>
								<div>现价:{{scope.row.Price2}}</div>
								<div>市场价:{{scope.row.Price}}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Url" label="链接"></el-table-column>
					<el-table-column label="操作" width="250">
						<template slot-scope="scope">
							<buttonPermissions :datas="'alreadyPriceBtn'">
								<el-button type="text" @click="EditPrice(scope.row)" style='margin-right: 10px;'>修改价格</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'alreadydeleteBtn'">
								<span style="color:#f00;margin-left:10px;cursor: pointer;" @click="deleteChose(scope.row)">删除</span>
							</buttonPermissions>
						</template>
					</el-table-column>
				</el-table>
				<div style="text-align: right;margin-top: 10px;">
					<el-pagination v-if="Total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
					 :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
					</el-pagination>
				</div>

			</div>

			<!-- //添加商品表单 -->
			<el-dialog title="修改价格" :visible.sync="addProVisible" width="800px" class="dialog data-dialog">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<div class="priceStyle" style="display: flex;margin-top: 20px;">
						<label style="color:#606266;font-size: 14px;margin-right: 10px;padding-top: 10px;">价格形式：</label>
						<div class="pricelist">
							<div class="price_item">
								<el-radio v-model="ruleForm.selectPrice" :label="1" label-width='100'>一口价</el-radio>
								<label style="margin-right:10px;text-align: left;">价格</label>
								<el-form-item prop="fixedPrice" :rules="ruleForm.selectPrice == 1?rules.fixedPrice:rules.nocheck" label-width="0px"
								 style="margin-bottom: 0px;">
									<el-input v-model="ruleForm.fixedPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==1?false:true"
									 @input='OnlyMoney(ruleForm.fixedPrice,1)'></el-input>
								</el-form-item>
								&nbsp;&nbsp;元
							</div>
							<div class="price_item">
								<el-radio v-model="ruleForm.selectPrice" :label="2" label-width='100'>价格区间</el-radio>
								<label style="margin-right:10px;text-align: left;">价格</label>
								<el-form-item prop="minPrice" :rules="ruleForm.selectPrice == 2?rules.minPrice:rules.nocheck" label-width="0px"
								 style="margin-bottom: 0px;">

									<el-input v-model="ruleForm.minPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==2?false:true"
									 @input='OnlyMoney(ruleForm.minPrice,2)'></el-input> 元 <span style="margin: 0 10px;">-</span>
								</el-form-item>
								<el-form-item prop="maxPrice" :rules="ruleForm.selectPrice == 2?rules.maxPrice:rules.nocheck" label-width="0px"
								 style="margin-bottom: 0px;">
									<el-input v-model="ruleForm.maxPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==2?false:true"
									 @input='OnlyMoney(ruleForm.maxPrice,3)'></el-input> 元
								</el-form-item>
							</div>
							<div class="price_item">
								<el-radio v-model="ruleForm.selectPrice" :label="3" label-width='100'>显示折扣价</el-radio>
								<label style="margin-right:10px;text-align: left;">市场价</label>
								<el-form-item prop="marketPrice" :rules="ruleForm.selectPrice == 3?rules.marketPrice:rules.nocheck" label-width="0px"
								 style="margin-bottom: 0px;">

									<el-input v-model="ruleForm.marketPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==3?false:true"
									 @input='OnlyMoney(ruleForm.marketPrice,4)'></el-input> 元
								</el-form-item>
								<label style="margin-right:10px;text-align: left;margin-left: 10px;">现价</label>
								<el-form-item prop="nowPrice" :rules="ruleForm.selectPrice == 3?rules.nowPrice:rules.nocheck" label-width="0px"
								 style="margin-bottom: 0px;">

									<el-input v-model="ruleForm.nowPrice" style="width: 100px;" :disabled="ruleForm.selectPrice==3?false:true"
									 @input='OnlyMoney(ruleForm.nowPrice,5)'></el-input> 元
								</el-form-item>
							</div>

						</div>

					</div>


				</el-form>

				<div class="footerBtn" style="text-align: center;">
					<el-button style="width: 120px;margin-right: 10px;" @click="addProVisible = false">取消</el-button>
					<el-button type="primary" style="width: 120px;" :loading="saveloading" @click="SaveInfo('ruleForm')">修改价格</el-button>
				</div>
			</el-dialog>
		</div>
	</no-auth>
</template>

<script>
	import {
		livestockedIndex,
		stockedEditprice,
		unstockedelete
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	
	import noAuth from './components/noAuth.vue';
	
	export default {
		components: {
			buttonPermissions,
			noAuth
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			}
			var checkfixedPrice = (rule, value, callback) => {

				if (!value && value !== 0) {
					return callback(new Error('请输入价格'));
				} else if (Number(value) > 9999999 || Number(value) == 0) {
					return callback(new Error('价格请设置在0.01~9999999之间'));
				} else {
					return callback();
				}
			}
			var checkmaxPrice = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入价格'));
				} else if (Number(value) > 9999999 || Number(value) == 0) {
					return callback(new Error('价格请设置在0.01~9999999之间'));
				} else {
					return callback();
				}
			}
			var checkminPrice = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入价格'));
				} else if (Number(value) > 9999999 || Number(value) == 0) {
					return callback(new Error('价格请设置在0.01~9999999之间'));
				} else {
					return callback();
				}
			}
			var checkmarketPrice = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入价格'));
				} else if (Number(value) > 9999999 || Number(value) == 0) {
					return callback(new Error('价格请设置在0.01~9999999之间'));
				} else {
					return callback();
				}
			}
			var checknowPrice = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入价格'));
				} else if (Number(value) > 9999999 || Number(value) == 0) {
					return callback(new Error('价格请设置在0.01~9999999之间'));
				} else {
					return callback();
				}
			}

			return {
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				groupData: [],
				loading: false,
				searchKey: '',
				addProVisible: false,
				ruleForm: {
					selectPrice: 1,
					fixedPrice: '',
					minPrice: '',
					maxPrice: '',
					marketPrice: '',
					nowPrice: '',
				},
				rules: {
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					fixedPrice: [{
						required: true,
						validator: checkfixedPrice,
						trigger: 'blur'
					}],
					minPrice: [{
						required: true,
						validator: checkminPrice,
						trigger: 'blur'
					}],
					maxPrice: [{
						required: true,
						validator: checkmaxPrice,
						trigger: 'blur'
					}],
					marketPrice: [{
						required: true,
						validator: checkmarketPrice,
						trigger: 'blur'
					}],
					nowPrice: [{
						required: true,
						validator: checknowPrice,
						trigger: 'blur'
					}],
				},
				saveloading: false,
				StockedCount: 0,
				surplusCount: 0,
				EditId: '',
			}
		},
		created() {
			this.getList()
		},
		methods: {
			//删除
			deleteChose(record) {
				this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					try {
						let result = await unstockedelete({
							Id: record.Id
						})
						if (result.IsSuccess) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '删除成功!'
							});
							this.getList()
						}
					} catch (err) {

					} finally {

					}


				}).catch(() => {
					this.$message({
						showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					this.currentPage = 1;
					setTimeout(() => {
						this.goodsSearch();
					}, 500)

				})

			},
			OnlyMoney(obj, index) {
				if (obj != '' && obj.substr(0, 1) == '.') {
					obj = "";
				}
				obj = obj.replace(/^0*(0\.|[1-9])/, '$1'); //粘贴不生效
				obj = obj.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
				obj = obj.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
				obj = obj.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
				obj = obj.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3') //只能输入两个小数
				if (obj.indexOf(".") < 0 && obj != "") { //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
					if (obj.substr(0, 1) == '0' && obj.length == 2) {
						obj = obj.substr(1, obj.length);
					}
				}
				if (index == 1) {
					this.ruleForm.fixedPrice = obj
				}
				if (index == 2) {
					this.ruleForm.minPrice = obj
				}
				if (index == 3) {
					this.ruleForm.maxPrice = obj
				}
			},
			goodsSearch() {
				this.currentPage = 1;
				this.getList();
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await livestockedIndex(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
					this.StockedCount = result.Result.StockedCount
					this.surplusCount = 5000 - this.StockedCount

				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false
					}, 500)

				}

			},
			//修改价格
			EditPrice(record) {
				this.addProVisible = true
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate()
				})
				this.EditId = record.Id
				this.ruleForm.selectPrice = record.PriceType
				if (record.PriceType == 1) {
					this.ruleForm.fixedPrice = record.Price
					this.ruleForm.minPrice = ''
					this.ruleForm.maxPrice = ''
					this.ruleForm.marketPrice = ''
					this.ruleForm.nowPrice = ''
				}
				if (record.PriceType == 2) {
					this.ruleForm.minPrice = record.Price
					this.ruleForm.maxPrice = record.Price2
					this.ruleForm.fixedPrice = ''
					this.ruleForm.marketPrice = ''
					this.ruleForm.nowPrice = ''
				}
				if (record.PriceType == 3) {
					this.ruleForm.marketPrice = record.Price
					this.ruleForm.nowPrice = record.Price2
					this.ruleForm.minPrice = ''
					this.ruleForm.maxPrice = ''
					this.ruleForm.fixedPrice = ''
				}
			},
			SaveInfo(formName) {
				if (this.ruleForm.selectPrice == 2) {
					
					if (Number(this.ruleForm.minPrice) >= Number(this.ruleForm.maxPrice)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请设置正确的价格区间'
						})
						return
					}
				}
				if (this.ruleForm.selectPrice == 3) {
					if (Number(this.ruleForm.marketPrice) <= Number(this.ruleForm.nowPrice)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '现价需低于市场价'
						})
						return
					}
				}
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveloading = true
							let data = {
								Id: this.EditId,
								PriceType: this.ruleForm.selectPrice,
							}
							if (data.PriceType == 1) {
								data.Price = this.ruleForm.fixedPrice
							}
							if (data.PriceType == 2) {
								data.Price = this.ruleForm.minPrice
								data.Price2 = this.ruleForm.maxPrice
							}
							if (data.PriceType == 3) {
								data.Price = this.ruleForm.marketPrice
								data.Price2 = this.ruleForm.nowPrice
							}
							let result = await stockedEditprice(data)

							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								setTimeout(() => {
									this.saveloading = false
								}, 2000)
								this.addProVisible = false
								this.getList()
							}
						} catch (e) {
							this.saveloading = false
						} finally {
							this.saveloading = false
						}


					} else {}
				});

			},
		},
	}
</script>


<style lang="less" scoped>
	.price_item {
		display: flex;
		align-items: center;
		margin-bottom: 34px;

		label {
			// width:40px;
			text-align: left;
		}
	}

	.overflowTwo {
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	::v-deep .el-form-item__error {
		// left: 38px!important;
		// margin-bottom: 5px;
		// width:177px;
	}
</style>
